<template>
    <UDashboardLayout>
        <UDashboardPanel :width="250" collapsible>
            <UDashboardSidebar>
                <template #header>
                    <UDashboardSidebarLinks :links="links" />
                </template>
                <UDivider />
                <UDashboardSidebarLinks :links="payLinks" />
                <UDivider />
                <UDashboardSidebarLinks :links="accountLinks" />
                <UDivider />
                <UDashboardSidebarLinks :links="partnerLinks" />
                <UDivider />
                <UDashboardSidebarLinks :links="insuranceLinks" />

                <div class="flex-1" />
                <UDashboardSidebarLinks :links="footerLinks" />
            </UDashboardSidebar>
        </UDashboardPanel>
        <slot />
    </UDashboardLayout>
</template>

<script setup lang="ts">
const route = useRoute()

const links = [
    {
        label: 'Admin Dashboard',
        icon: 'i-heroicons-chart-bar',
        to: { name: 'admin' },
        exact: true,
    }
];

const payLinks = [
    {
        label: 'Insurance Payments',
        icon: 'i-heroicons-building-library',
        children: [{
            label: 'Payments',
            to: { name: 'admin-insurance-payments' }
        }, {
            label: 'Itemized',
            to: { name: 'admin-insurance-payment-items' }
        }],
    }, {
        label: 'Client Payments',
        icon: 'i-heroicons-credit-card',
        children: [{
            label: 'Payments',
            to: { name: 'admin-payments' }
        }, {
            label: 'Itemized',
            to: { name: 'admin-payment-items' }
        }],
    }, {
        label: 'Payouts',
        icon: 'i-heroicons-banknotes',
        children: [{
            label: 'Payout Items',
            to: { name: 'admin-payout-items' }
        }, {
            label: 'All Payouts',
            to: { name: 'admin-payouts' }
        }],
    }, {
        label: 'Numo',
        icon: 'i-heroicons-building-library',
        defaultOpen: false,
        children: [{
            label: 'Companies',
            to: { name: 'admin-numo-companies' }
        }, {
            label: 'Invoices',
            to: { name: 'admin-numo-invoices' }
        }],
    }
];
const accountLinks = [
    {
        label: 'Accounts',
        icon: 'i-heroicons-rectangle-group',
        to: { name: 'admin-accounts' },
    }, {
        label: 'Clients',
        icon: 'i-heroicons-user-group',
        to: { name: 'admin-clients' },
    }, {
        label: 'Claims',
        icon: 'i-heroicons-inbox-stack',
        to: { name: 'admin-claims' },
    }, {
        label: 'Users',
        icon: 'i-heroicons-user-circle',
        to: { name: 'admin-users' },
    }, {
        label: 'Authentication',
        icon: 'i-heroicons-shield-check',
        children: [{
            label: 'Firebase',
            to: { name: 'admin-users-fb' }
        }, {
            label: 'Supabase',
            to: { name: 'admin-auth' }
        }],
    },
    // {
    //     label: 'Settings',
    //     icon: 'i-heroicons-cog-8-tooth',
    //     children: [{
    //         label: 'General'
    //     }, {
    //         label: 'Members'
    //     }, {
    //         label: 'Notifications'
    //     }],
    // },
];

const partnerLinks = [
    {
        label: 'Partners',
        icon: 'i-heroicons-building-storefront',
        to: { name: 'admin-partners' },
    }, {
        label: 'Providers',
        icon: 'i-mdi-stethoscope',
        to: { name: 'admin-providers' },
    }
];
const insuranceLinks = [
    {
        label: 'Insurance',
        icon: 'i-heroicons-building-office',
        to: { name: 'admin-insurance' },
    }, {
        label: 'Policies',
        icon: 'i-heroicons-identification',
        to: { name: 'admin-policy' },
    }, {
        label: 'Addresses',
        icon: 'i-heroicons-map',
        to: { name: 'admin-addresses' },
    }
];
const footerLinks = [
    {
        label: 'Back to Admin',
        icon: 'i-heroicons-arrow-uturn-left',
        to: { name: 'admin' },
    }
]

</script>
